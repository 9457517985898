.footer {
  &--wrapper {
    margin: 0 auto;
    padding: 3rem 0 1rem;
    width: 100%;
    position: relative;
    z-index: 2;
    color: $white;
    background: $linear-bg-2;
  }
  &--socialmedia {
    margin: auto;  /* centralise this item */
    padding: 0rem 0 5rem 0;
    width: 33.3%;

    .socialmedia {
      &--wrapper {
        padding: 2rem;
        display: flex;
        align-items: center;
        a {
          color: $white;
          text-align: center;
          &:hover {
            color: $black;
            transition: color 0.5s
          }
        }
      }
      &--icon {
        min-width: 50px;  /* for align different size of icons */
      }
      &--descriptions {
        padding-left: 1rem;
        text-align: left;
        a {
          font-weight: bold;
        }
      }
    }
  }
}
