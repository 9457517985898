/**
 *  This is the App style sheet
 **/

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  height: 100%;
  color: $white;
  background-image: $linear-bg-3;
  text-align: center;
}

// a text box centralise all text in the center
.text-box {
  width: 100%;
  height: 100%;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  p {
    padding: 1rem 0;
  }
}
