.about {
  &--wrapper {
    position: relative;
    z-index: 2;
    height: 100vh;
    background-image: linear-gradient(141deg, #ffffff 0%, #dddddd 51%, #9a9a9a 75%);
    font-family: $font-primary;

    #about {  /* This is for the offset of navbar */
      position: absolute;
      top: -$navbar-height;
    }
    
    .row {
      height: 100%;
      background-color: $black;
      /* About Page - Left Section */
      .about--left-section {
        position: relative;
        height: 100%;
        .about--left-wrapper {
          padding: 100px 1rem;
          height: 100%;
          font-family: $about-title;

          .about--avatar {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            opacity: 1;

            .avatar {
              &--wrapper {
                width: 200px;
                height: 200px;
                position: relative;
                z-index: 2;
                opacity: 0;

                img {
                  width: 100%;
                  height: 100%;
                  border-radius: 50%;
                }
              }
              &--text {
                position: relative;
                padding-top: 2rem;
                color: $black;
                opacity: 0;
                &-name {
                  font-size: 2.5rem;
                  font-weight: bold;
                }
                &-title {
                  font-size: 1rem;
                }
              }
            }
          } /* about--avatar */

          .about--specialization {
            position: relative;
            padding-top: 3rem;
            color: $black;
            opacity: 0;

            .specialization {
              &--text {
                font-size: 1.5rem;
              }
              &--icons {
                padding: 0rem 3rem 5rem;
                display: grid;
                grid-template-columns: 100px 100px 100px;
                justify-content: center;
              }
              &--icon {
                padding: 10px;
                img {
                  margin: 0;
                  padding: 0;
                  max-width: 3.6em;
                }
                .icon--html5 {
                  color: #e34f26;
                }
                .icon--css3 {
                  color: #039be6;
                }
                .icon--javascript {
                  color: #f0Db4f;
                }
                .icon--react {
                  color: #61dafb;
                }
                .icon--wordpress {
                  color: #2962ff;
                }
                .icon--database {
                  color: #000000;
                }
              }
            }
          } /* about about--specialization end */

        } /* about--left-wrapper end */
      } /* about--left-section end */

      /* About Page - Right Section */
      .about--right {
        &-section {
          position: relative;
          padding: 5rem 5%;
          height: 100%;
          color: $white;
          @include content-text-size($desktop-font-size);
        }
        &-bg {
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background-image: url(../images/about-bg1.jpg);
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          opacity: 0;
        }
        &-lighteffect {
          position: absolute;
          z-index: 1;
          top: 0px;
          right: 0px;
          color: #fff;
          background-color: #fff;
        }
        &-wrapper {
          opacity: 0;
        }
      } /* about--right end */

    } /* row end */
  } /* about--wrapper end */
} /* about end */

/***
  Animation
  param: name, duration, time function, delay, iteration count, direction, fill mode, play state
  Effect Order: from top to bottom
 ***/
.avatar--slide-left {
  @include animation(avatar--slide-left, 1s, linear, 0s, 1, normal, forwards, running);
}

.light--brighten-up {
  @include animation(light--brighten-up, 0.5s, linear, 1s, 1, normal, forwards, running);
}

.intro--brighten-up {
  @include animation(intro--brighten-up, 1s, linear, 1s, 1, normal, forwards, running);
}

.bg--fade-out {
  @include animation(bg--fade-out, 1s, linear, 1s, 1, normal, forwards, running);
}

.avatartext--slide-left {
  @include animation(avatartext--slide-left, 0.5s, linear, 2s, 1, normal, forwards, running);
}

.specialization--slide-up {
  @include animation(specialization--slide-up, 0.5s, linear, 2.5s, 1, normal, forwards, running);
}

.introtext--show-up {
  @include animation(introtext--show-up, 1s, linear, 2s, 1, normal, forwards, running);
}

@keyframes avatar--slide-left {
  0% {left: 40%;}
  25% {left: 30%;}
  50% {left: 20%; opacity: 0.5;}
  75% {left: 10%;}
  100% {left: 0%; opacity: 1;}
}

@keyframes avatartext--slide-left {
  0% {left: 40%;}
  25% {left: 30%;}
  50% {left: 20%; opacity: 0.5;}
  75% {left: 10%;}
  100% {left: 0%; opacity: 1;}
}

@keyframes specialization--slide-up {
  0% {top: 20%; opacity: 0;}
  50% {top: 10%; opacity: 0.5;}
  100% {top: 0%; opacity: 1;}
}

@keyframes light--brighten-up {
  25% {box-shadow: 0px 0px 90px 90px #fff;}
  50% {box-shadow: 0px 0px 90px 90px #fff, 0px 0px 150px 120px #b5b5b5;}
  100% {box-shadow: 0px 0px 90px 90px #fff, 0px 0px 150px 120px #b5b5b5, 0px 0px 180px 150px #919191 ;}
}

@keyframes intro--brighten-up {
  0% {opacity: 0;}
  100% {opacity: 0.3}
}

@keyframes bg--fade-out {
  0% {background-color: $black}
  100% {background-color: $white}
}

@keyframes introtext--show-up {
  0% {opacity: 0; filter: blur(5px);}
  50% {opacity: 0.5; filter: blur(3px);}
  100% {opacity: 1; filter: blur(0px);}
}
