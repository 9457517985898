.projects {

  &--wrapper {
    position: relative;
    z-index: 2;
    color: $white;
    scroll-margin-top: 65px;
    #projects {  /* This is for the offset of navbar */
      position: absolute;
      top: -$navbar-height;
    }
  }

  &--intro-wrapper {
    padding: 5rem 0;
    background-image: $linear-bg-1;
    opacity: 0.75;
    .project--card {
      grid-column: span 3;
    }
  }

  &--intro-text {
    display: flex;
    justify-content: center;
    @include content-text-size($desktop-font-size);
    position: relative;
    h2 {
      margin: 0;
      padding: 0 1rem;
      line-height: normal;
    }
    .icon--javascript {
      opacity: 0;
    }
  }

  &--intro-projects {
    margin: 5% auto;
    width: 80%;
    position: relative;
    z-index: 2;
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    align-content: center;
  }

  
} /* project end */

// animation trigger
.active {
  svg {
      position: relative;
      animation-name: slide-left;
      animation-duration: 2s;
      animation-iteration-count: 1;
      animation-fill-mode: forwards;
    }
}
// Animation 
@keyframes slide-left {
  0% {transform: translateX(-100%); opacity: 0;}
  100% {opacity: 1;}
}
