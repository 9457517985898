.project--card {
  min-height: 500px;
  position: relative;
  overflow: hidden;
  font-family: $project-title;
  opacity: 0.5;
  color: $white;

  &-bg {
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    transition: transform 0.3s;
  }

  .card--content {
    padding: 3rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    word-break: normal;
    img {
      max-width: 200px;
    }
    h4 {
      padding: 1rem 2rem;
      color: $white;
      background-color: $pink;
      border-radius: 5px;
      opacity: 0;
      transition: 0.5s;
    }

  }

  .project--card-description {
    position: absolute;
    bottom: -3rem;
    left: 0;
    width: 100%;
    height: 3rem;
    padding: 1rem;
    line-height: 1;
    color: $white;
    background-color: rgb(68, 68, 68);
    text-align: center;
    transition: bottom 0.5s;
  }

  &:hover, &:focus {
    opacity: 1;
    .project--card-description {
      bottom: 0;
    }
    .project--card-bg {
      transform: scale(1.1);
    }
    h4 {
      opacity: 1;
    }
  }
}

.card--span-2 {  /* for occupy two column space */
  grid-column: span 2;
}

.card--span-3 { 
  grid-column: span 3;
}