
$mobile-width: 601px;
$tablet-width: 768px;
$desktop-width: 992px;
$large-desktop-width: 1200px;

@mixin mobile { // <=600px
  @media only screen and (max-width: #{$mobile-width - 1px} ) {
    @content;
  }
}

@mixin tablet { // from 601px to 991px
  @media (min-width: #{$mobile-width}) and (max-width: #{$desktop-width - 1px}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

// animation
@mixin animation($name, $duration, $timingFunc, $delay, $iterationCount, $direction, $fillMode, $playState) {
  animation-name: $name;
  animation-duration: $duration; /* "time|initial|inherit" */
  animation-timing-function: $timingFunc ; /* "linear|ease|ease-in|ease-out|cubic-bezier(n, n, n, n)|initial|inherit" */
  animation-delay: $delay; /* time|initial|inherit" */
  animation-iteration-count: $iterationCount; /* "number|infinite|initial|inherit" */
  animation-direction: $direction; /* "normal|reverse|alternate|alternate-reverse|initial|inherit" */
  animation-fill-mode: $fillMode; /* "none|forwards|backwards|both|initial|inherit" */
  animation-play-state: $playState; /* "running|paused|initial|inherit" */
}

// font size
@mixin content-text-size($defaultSize) {
  font-size: $defaultSize;
}
