/**
 *  This is the base style sheet
 **/

// Google fonts
@import url('https://fonts.googleapis.com/css?family=Open+Sans|Roboto|Lora|Sriracha');
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');

 body {
   font-family: 'Open Sans', 'Roboto', 'Oxygen',
     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
     sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
 }

 code {
   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
     monospace;
 }
