/**
  * This sytle sheet store all the App's variables
  **/

$navbar-height: 70px;

// Colour
$white: #fff;
$black: #000;
$darkgrey: #252424;
$lightgrey: #5d5b5b;
$blue: #0083ee;
$pink: #c69;

// Theme Colour
$primary: $white;

// Fonts
$font-primary: 'Open Sans';
$font-title: 'Roboto';
$font-text: 'Open Sans';
$hero-title: 'Lora';
$about-title: 'Sriracha';
$project-title: 'Sriracha';

// Font font-size
$desktop-font-size: 2rem;
$mobile-font-size: 1.5rem;

// radial-gradient
$rad-grad-colour-1: #140e14;
$rad-grad-colour-2: #000000;
$rad-grad-colour-3: #201620;

// text-shadow
$text-shadow-colour-1: #FFF;
$text-shadow-colour-2: $pink;
$text-shadow-colour-3: $blue;

// linear background Colour
$linear-bg-1: linear-gradient(135deg, #d29fb7, #da448f);
$linear-bg-2: linear-gradient(135deg, $pink, #cd86a9);
$linear-bg-3: linear-gradient(135deg, $lightgrey, $black);

