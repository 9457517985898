.hero {
  &--wrapper {
    position: relative;
    z-index: 3;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;  // avoid the viewport width more than the screen
    color: $white;  /* for text only */
    text-align: center;
    text-transform:capitalize;
    background: radial-gradient(farthest-corner at 10px 10px, $rad-grad-colour-1 0%, $rad-grad-colour-2 100%);
  }
  &--h1 {
    padding: 0 10%;
    color: $white;
    font-family: $hero-title;
    text-align: center;
    text-shadow: $text-shadow-colour-1 0px 0px 2px, $text-shadow-colour-1 0px 0px 4px, $text-shadow-colour-1 0px 0px 8px, $text-shadow-colour-2 0px 0px 20px, $text-shadow-colour-2 0px 0px 30px, $text-shadow-colour-2 0px 0px 40px;

    animation-name: neno-flicker;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    &:before {
      content: 'As no one is identically same, we have different talent';
      animation-name: swap-text;
      animation-duration: 10s;
      animation-delay: 4s;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
    }
  }
  &--star {
    position: absolute;
    width: 1px;
    height: 1px;
    background-color: $white;
    animation-name: twinkle;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }
}

/***
  *  Animation
  **/

@keyframes neno-flicker {
  0% {text-shadow: $text-shadow-colour-1 0px 0px 2px, $text-shadow-colour-1 0px 0px 4px, $text-shadow-colour-1 0px 0px 8px, $text-shadow-colour-2 0px 0px 20px, $text-shadow-colour-2 0px 0px 30px, $text-shadow-colour-2 0px 0px 40px;}
  50% {text-shadow: $text-shadow-colour-1 0px 0px 1px, $text-shadow-colour-1 0px 0px 2px, $text-shadow-colour-1 0px 0px 8px, $text-shadow-colour-2 0px 0px 10px, $text-shadow-colour-2 0px 0px 20px, $text-shadow-colour-2 0px 0px 30px;}
  100% {text-shadow: $text-shadow-colour-1 0px 0px 2px, $text-shadow-colour-1 0px 0px 4px, $text-shadow-colour-1 0px 0px 8px, $text-shadow-colour-2 0px 0px 20px, $text-shadow-colour-2 0px 0px 30px, $text-shadow-colour-2 0px 0px 40px;}
}

@keyframes twinkle {
  0% { opacity: 1 }
  50% { opacity: 0 }
  100% { opacity: 1 }
}

@keyframes swap-text { /* best timing for swap between texts */
  0% {opacity: 1;}
  10% {opacity: 0;}
  20% {content: "Continuous learning is the key to success";}
  30% {}
  40% {opacity: 1;}
  50% {}
  60% {opacity: 0;}
  70% {}
  80% {}
  90% {}
  100% {opacity: 1;}
}
