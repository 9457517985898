/**
  *  This stylesheet reset the margin and padding of the browser default settings
  */

html, body {
  margin: 0;
  padding: 0;
}

div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,pre,form,fieldset,input,textarea,p,blockquote,th,td {
    margin:0;
    padding:0;
}
