.preloader--bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background-color: $black;
  color: $black;
  opacity: 1;
}
.preloader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: display 5s;

  .spinner-layer {
    border-color: $white;
  }
  .preloader-wrapper {
    width: 5rem;
    height: 5rem;
  }

  @include tablet {
    .preloader-wrapper {
      width: 4rem;
      height: 4rem;
    }
  }
  @include mobile {
    .preloader-wrapper {
      width: 3rem;
      height: 3rem;
    }
  }
}

.bg-fade-out {
  animation-name: bg-fade-out;
  animation-duration: 1s;
  animation-fill-mode: forwards;
}

.fade-out {
  animation-name: fade-out;
  animation-duration: 1.5s;
  animation-fill-mode: forwards;
}

@keyframes bg-fade-out {
  50% {background-color: #000; opacity: 1;}
  100% {background-color: transparent; opacity: 0; display: none; z-index: -1}
}

@keyframes fade-out {
  70% {opacity: 1;}
  90% {opacity: 0;}
  100% {display: none;}
}
