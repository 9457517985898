/***
  *  Mobile and Tablet
  **/


@include tablet {  /* stop animation at some points */
  .navbar {
    .sidenav-trigger {
      display: none;
    }
  }
  .about {
    &--wrapper {
      height: 100%;
      .row {
        height: 100%;
        margin: 0;

        .about--left-section { /* cover the shadow by the light effect */
          position: relative;
          z-index: 99;
        }

        .about--right {
          &-bg {
            opacity: 0.3;
          }
          &-wrapper {
            opacity: 1;
          }
        }
      }
    }
  }

  .projects {
    &--intro-text {
      .icon--javascript {
        opacity: 0;
      }
    }
    &--intro-projects {
      margin: 0;
      width: 100%;
      height: 100%;      
      display: block;
      .project--card {        
        min-height: 400px;
        opacity: 1;
        border: 10px solid $white;
        h4 {
          font-size: 1.5rem;
          opacity: 1;
        }
      }
      
      .project--card-description {
        bottom: 0;
      }
    }

  }
  
} /* tablet end */

@include mobile {
  .hero {
    &--wrapper {
      background-image: url("../images/mobile-hero-bg.png");
      background-repeat: no-repeat;
      background-size: cover;
    }
    &--h1 {
      padding: 0 1rem;
      font-size: 3rem;
      animation-name: none /* for stop this animation */
    }
  } /* end of Hero Section */

  .navbar {
    line-height: $navbar-height;
    .nav-wrapper {
      padding-left: 1rem;
        i {
          height: inherit;
          line-height: inherit;
          font-size: 3rem;
        }
    }
  }

  .about {
    &--wrapper {
      height: 100%;
      .row {
        height: 100%;
        margin: 0;

        .about--left {
          &-section { /* cover the shadow by the light effect */
            position: relative;
            z-index: 99;
            .about--left-wrapper {
              padding: 2rem 1rem;
            }
          }
        }


        .about--right {
          &-section {
            padding: 4rem 0rem;
            @include content-text-size($mobile-font-size);
          }
          &-bg {
            opacity: 0.3;
          }
          &-wrapper {
            opacity: 1;
            width: 100%;
            padding: 0 1rem;
          }
        }
      }
    }
  } /* about section end */

  .projects {
    &--wrapper {
      .container {
        width: 100%;
      }
    }

    &--intro-wrapper {
      padding: 2rem 0rem;
    }

    &--intro-text {
      padding: 0 1rem;
      @include content-text-size($mobile-font-size);
      h2 {
        font-size: 1.5em;
      }
    }

    &--intro-projects {
      margin: 0;
      width: 100%;
      height: 100%;
      display: block;
      .project--card {
        min-height: 300px;
        opacity: 1;
        border: 10px solid $white;
        h4 {
          font-size: 1.5rem;
          opacity: 1;
        }
        .project--card-description {
          bottom: 0;
        }
      }
      .card--span-2 {  /* for occupy two column space */
        grid-column: span 1;
      }
    }
  }

  .footer {
    &--wrapper {
      padding: 1rem;
      .footer--socialmedia {
        width: 100%;
        padding-bottom: 2rem;
        .socialmedia--wrapper {
          padding: 1rem;
        }
      }
    }
  }

} /* mobile end */
