.navbar {
  position: sticky;
  top: 0;
  z-index: 3; // fixed the sunlight effect that override this element
  border-bottom: solid $pink 5px;
  height: $navbar-height;

  .nav-wrapper {
    padding-left: 2rem;
    background-color: #2a2b2d;
    ul{
      a {
        font-size: 1.5rem;
        &:hover {
          color: $pink;
        }
      }
    }
  }
}

#mobile-nav {
  padding-top: 50px;
  li {
    padding: 20px 10px;
    a {
      font-weight: 600;
      font-size: 2rem;
      color: $pink;
    }
  }
}
